import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LinkCard = ({ to, title, icon, className }) => (
    <Link to={to} className={`${className} 
    p-4
    flex
    flex-col
    justify-center
    items-center
    bg-gray-800
    text-gray-200 
    hover:text-main
    h-full
    `}>
      <FontAwesomeIcon icon={icon} size="6x" className="text-gray-400 mt-8"/>
      <span className="uppercase text-center mt-8 mb-8 text-xl font-bold">{title}</span>
    </Link>
)

LinkCard.propTypes = {
  to: PropTypes.string
}

export default LinkCard
