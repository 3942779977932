import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
import PropTypes from 'prop-types'
import LinkCard from '../components/LinkCard'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

const IndexPage = ({ data: { postsAllMarkdownRemark: { edges: posts } } }) => (
  <Layout>
    <GatsbySeo title={`IPPAL Translation Company`} description={`IPPAL Translation Company`} />

    <div className="flex flex-col items-center justify-center w-full">
       <StaticImage
          src="../images/bg.jpg"
          alt={"foo"}
          title={"foo"}
          className={"foo"}
          placeholder="blurred"
          layout="fullWidth"
        />
      <div className="mx-auto flex flex-col p-4 xl:w-3/4 justify-center items-center flex-wrap grid md:grid-cols-2 lg:grid-cols-5 grid-cols-1 gap-1 bg-gray-700 ">
        <LinkCard to={'/patent-translation'} title="patent translation" icon="pen-fancy">foo</LinkCard>
        <LinkCard to={'/evaluation'} title="evaluation" icon="search" className=""/>
        <LinkCard to={'/intercultural-bridge-transformation'} title="intercultural bridge / transformation" icon="random" className="lg:row-span-3">foo</LinkCard>
        <LinkCard to={'/monitoring'} title="monitoring competitor's ip" icon="eye">foo</LinkCard>
        <LinkCard to={'/languages'} title="languages" icon="database" className="">foo</LinkCard>
        <LinkCard to={'/product-theft'} title="discover product theft and locate the source" icon="user-secret">foo</LinkCard>
        <LinkCard to={'/legalization'} title="legalization" icon="balance-scale">foo</LinkCard>
        <LinkCard to={'/interpretation'} title="interpreting from and into chinese language" icon="language" className="lg:col-span-2">foo</LinkCard>
        <LinkCard to={'/industries'} title="industries" icon="industry" className="lg:col-span-2">foo</LinkCard>
        <LinkCard to={'/patent-infringement-services'} title="patent infringement services" icon="gavel" className="">foo</LinkCard>
        <LinkCard to={'/patent-validity-services'} title="patent validity services" icon="check">foo</LinkCard>
        <LinkCard to={'/translations'} title="translations in litigation and ipr proceedings" icon="comments" className="lg:col-span-3">foo</LinkCard>
        <LinkCard to={'/strategy-services'} title="strategy services for china patent litigation and IPS proceedings" icon="tasks" className="lg:col-span-2">foo</LinkCard>
      </div>

      {/* <div className="w-full lg:w-1/2">
        {posts.map(
          ({
            node: {
              fields: { slug },
              frontmatter: { title, description, featuredimage },
            },
          }) => (
              <div
                key={slug}
                className="rounded w-full flex flex-col lg:flex-row mb-5"
              >
                <Link to={slug}>
                  <GatsbyImage
                    image={getImage(featuredimage)}
                    alt={title}
                    title={title}
                    className="block lg:block rounded-md h-32 m-4 lg:m-0"
                  />
                </Link>
                <div className="bg-white rounded px-4">
                  <Link to={slug}>
                    <h3 className="text-gray-800 font-semibold text-lg lg:text-xl mb-2">
                      {title}
                    </h3>
                  </Link>
                  <p className="block lg:hidden p-2 pt-1 text-sm text-gray-600">
                    {description}
                  </p>
                </div>
              </div>
            )
        )}
          </div>*/}
    </div>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape({
    postsAllMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    postsAllMarkdownRemark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: 0
      limit: 5
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  height: 120
                  width: 350
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: CONSTRAINED
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
